<template>

    <div>
      <div id="fullcontent">
        <router-view/>
      </div>
    </div>

</template>

<script>
  import {Api} from '@/core/api.js';
  const api = new Api();

  export default {
      components: { },
      data () {
        return {}
      },
      methods: {
      },
      created() {
        //this.$bar.hide()
      }
  }
</script>

<style lang="scss" >

#fullcontent.inOffline {
  width: 100%;
  height: 100%;
  z-index: $z-content;
  position: static;
  overflow-x: hidden;
  overflow-y: auto;
}

</style>
